<template>
  <!-- <div id="vip"> -->
  <div class="section">
    <div class="tit">升级VIP会员服务</div>
    <ul class="list">
      <li>
        <h4>高级会员</h4>
        <p class="price">1年仅需<span>1999</span>元</p>
        <div class="rights">
          <p>赠送PC端店铺</p>
          <p>开通在线支付</p>
          <p>赠送10000积分</p>
        </div>
        <!-- <router-link to="" class="vip-btn">立即升级</router-link> -->
        <span class="vip-btn" @click="handleGj">立即升级</span>
      </li>
      <li>
        <h4>首席会员</h4>
        <p class="price">1年仅需<span>6999</span>元</p>
        <div class="rights">
          <p>赠送定制化店铺</p>
          <p>店铺定制</p>
          <p>开通在线支付</p>
          <p>赠送50000积分</p>
        </div>
        <span class="vip-btn" @click="handleSx">立即升级</span>
      </li>
      <li>
        <h4>至尊会员</h4>
        <p class="price">成为平台战略合作伙伴</p>
        <div class="rights">
          <img src="@/assets/img/vip-list-3-rights.png" />
        </div>
        <span class="vip-btn" @click="handleZz">立即升级</span>
      </li>
    </ul>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  methods: {
    handleGj() {
      let params = {
        project: "高级会员",
      };
      this.$axios.post("/applyService", params).then((data) => {
        if (data.code == 41004) {
          this.$message.error(data.msg);
          return;
        } else {
          this.$alert(
            "申请成功，我们的客户经理将于1个工作日内与您联系！",
            "高级会员",
            {
              type: "success",
            }
          );
        }
      });
    },
    handleSx() {
      let params = {
        project: "首席会员",
      };
      this.$axios.post("/applyService", params).then((data) => {
        if (data.code == 41004) {
          this.$message.error(data.msg);
          return;
        } else {
          this.$alert(
            "申请成功，我们的客户经理将于1个工作日内与您联系！",
            "首席会员",
            {
              type: "success",
            }
          );
        }
      });
    },
    handleZz() {
      let params = {
        project: "至尊会员",
      };
      this.$axios.post("/applyService", params).then((data) => {
        if (data.code == 41004) {
          this.$message.error(data.msg);
          return;
        } else {
          this.$alert(
            "申请成功，我们的客户经理将于1个工作日内与您联系！",
            "至尊会员",
            {
              type: "success",
            }
          );
        }
      });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.section {
  width: 1267px;
  background: url(../../assets/img/vip-bg.png) no-repeat top center;
  background-size: 100% auto;
  padding-bottom: 66px;
  background-color: #fff;
}

.tit {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  padding: 56px 0 43px;
}

.list {
  display: flex;
  justify-content: space-around;
  margin: 0 40px;
}

.list li {
  width: 342px;
  height: 437px;
  background-size: 100% 100%;
  color: #fff;
}

.list li:nth-of-type(1) {
  background-image: url(../../assets/img/vip-list-1.png);
}

.list li:nth-of-type(2) {
  background-image: url(../../assets/img/vip-list-2.png);
}

.list li:nth-of-type(3) {
  background-image: url(../../assets/img/vip-list-3.png);
}

.list h4 {
  font-size: 30px;
  color: #decb9a;
  letter-spacing: 2px;
  text-align: center;
  margin-top: 37px;
}

.price {
  height: 50px;
  font-size: 18px;
  color: #decb9a;
  text-align: center;
  margin-top: 25px;
}

.price span {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 3px;
}

.rights {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 132px;
  font-size: 18px;
  margin-top: 61px;
}

.rights img {
  width: 153px;
  height: 97px;
  margin-top: 31px;
}

.vip-btn {
  display: block;
  width: 180px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  margin: 26px auto 0;
  background: linear-gradient(#d2be91, #cbb68a, #bfab81, #b4a179, #ac9973);
  border-radius: 20px;
  color: #976632;
  font-size: 18px;
}
</style>
