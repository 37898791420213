<template>
  <div class="section">
    <div class="tit">
      <div>公告</div>
      <router-link :to="{ path: '/announcement' }">
        更多<i class="el-icon-arrow-right" />
      </router-link>
      <!-- <a :href="$store.state.ROOT_URL+'/help-register'" target="_blank">更多</a> -->
    </div>
    <ul class="list">
		<li v-for="(item,index) in data" :key="item.id">
			<router-link :to="{path:'/announcement/read',query:{id:item.id}}">
				{{item.title}}
			</router-link> 
		</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["data"],
  created() {
    this.id = this.$route.query.id;
  },
};
</script>

<style scoped>
.section {
  padding: 0 14px;
  background-color: #fff;
  margin-top: 20px;
}

.tit {
  display: flex;
  font-size: 14px;
  color: #000000;
  padding: 20px 7px;
  border-bottom: 1px solid #f6f5f7;
}

.tit div {
  display: flex;
}

.tit div::before {
  content: "";
  width: 14px;
  height: 14px;
  background: url(../../assets/img/notice-tit.png);
  background-size: 100% 100%;
  margin-right: 8px;
}

.tit a {
  font-size: 12px;
  color: #999999;
  margin-left: auto;
}

.list {
  padding: 8px 0 37px;
  font-size: 12px;
  line-height: 24px;
}

.list li {
  margin-top: 5px;
}

.list a {
  text-decoration: none;
  color: #595959;
}
</style>
