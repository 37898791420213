<template>
  <div class="section">
    <div class="top">
      <router-link to="/shopTemplate" class="pc">
        <div class="top-tit">电脑网站</div>
        <p class="top-int">拖拽排版，10分钟完成搭建，专注全网营销</p>
        <div class="top-btn">去设计<i class="el-icon-arrow-right" /></div>
      </router-link>
      <span class="mobile" @click="handleApply">
        <div class="top-tit">手机网站</div>
        <p class="top-int">覆盖移动端，支持微信、头条等传播</p>
        <div class="top-btn">立即开通<i class="el-icon-arrow-right" /></div>
      </span>
      <div class="domain">
        <div class="domain-tit">{{data.seller_title}}</div>
        <a class="domain-int" :href="data.url" target="_blank">{{data.url}}</a>
        <div class="domain-form">
          <div class="domain-input">
            <input
              type="text"
              placeholder="www.独立域名抢注"
              id="yuming"
              v-model="url"
            />
            <span>.com</span>
          </div>
          <div class="domain-btn" @click="handleBindUrl">绑定</div>
        </div>
      </div>
    </div>
    <div id="vip">
      <div class="list">
        <a :href="$store.state.ROOT_URL+'/jianzhan'" target="_blank">
        	<div>
        		<img src="@/assets/img/built-1.png">
        	</div>
        	<p>网站定制</p>
        </a>
        <a :href="$store.state.ROOT_URL+'/bdxcx'" target="_blank">
        	<div>
        		<img src="@/assets/img/built-2.png">
        	</div>
        	<p>百度小程序</p>
        </a>
        <router-link to="/applet">
        	<div>
        		<img src="@/assets/img/built-3.png">
        	</div>
        	<p>微信小程序</p>
        </router-link>
        <a :href="$store.state.ROOT_URL+'/seo'" target="_blank">
        	<div>
        		<img src="@/assets/img/built-4.png">
        	</div>
        	<p>优化托管</p>
        </a>
        <router-link to="/cusCl">
        	<div>
        		<img src="@/assets/img/built-5.png">
        	</div>
        	<p>店铺商品分类</p>
        </router-link>
        <router-link to="/leaflet1">
        	<div>
        		<img src="@/assets/img/built-6.png">
        	</div>
        	<p>环保商机订阅</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      url: "",
    };
  },
  created() {},
  methods: {
    handleApply() {
      let params = {
      	project: '手机网站'
      }
      this.$axios.post('/applyService', params).then(data => {
      	if (data.code == 41004) {
      		this.$message.error(data.msg)
      		return
      	} else {
      		this.$alert('申请成功，我们的客户经理将于1个工作日内与您联系！', '手机网站', {
      			type: 'success'
      		})
      	}
      })
    },
    handleBindUrl() {
      let params = {
      	project: "域名绑定：" + document.getElementById("yuming").value
      }
      if (this.url) {
      	this.$axios.post('/applyService', params).then(data => {
      		if (data.code == 41004) {
      			this.$message.error(data.msg)
      			return
      		} else {
      			this.$alert('申请成功，我们的客户经理将于1个工作日内与您联系！', '域名绑定', {
      				type: 'success'
      			})
      		}
      	})
      }
    },
  },
};
</script>

<style scoped>
.section {
  padding: 36px 30px 25px;
  background-color: #fff;
}
.top {
  display: flex;
}
.top > a,
.top > span {
  width: 411px;
  height: 175px;
  color: #fff;
  background-size: 100% 100%;
  padding-left: 23px;
  margin-left: 24px;
}

.top > span {
  cursor: pointer;
}

.top .pc {
  background-image: url(../../assets/img/b-pc.png);
  background-size: cover;
}

.top-tit {
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-top: 38px;
}

.top-tit::before {
  content: "";
  width: 37px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: 2px center;
}

.pc .top-tit::before {
  background-image: url(../../assets/img/pc.png);
  background-size: 27px 22px;
}

.top-int {
  margin: 16px 0 24px;
  font-size: 16px;
}

.top-btn {
  width: 86px;
  height: 26px;
  background: #fff;
  border-radius: 20px;
  color: #0094fd;
  font-size: 14px;
  text-align: center;
  line-height: 26px;
}

.top .mobile {
  background-image: url(../../assets/img/b-mobile.png);
}

.mobile .top-tit::before {
  background-image: url(../../assets/img/mobile.png);
  background-size: 17px 25px;
}

.mobile .top-btn {
  color: #62ced9;
}

.domain {
  flex: 1;
  margin-left: 38px;
}

.domain-tit {
  font-size: 20px;
  margin-top: 34px;
}

.domain-int {
  display: block;
  font-size: 18px;
  color: #999999;
  margin-top: 32px;
}

.domain-form {
  margin-top: 11px;
  display: flex;
}

.domain-input {
  width: 269px;
  height: 31px;
  border: 1px solid #e5e5e5;
  display: flex;
  font-size: 14px;
  align-items: center;
}

.domain-input input {
  border: none;
  text-indent: 9px;
  width: 220px;
}

.domain-btn {
  width: 62px;
  height: 31px;
  border: 1px solid #a7b7f0;
  background: #f4f6ff;
  color: #5874d8;
  font-size: 14px;
  margin-left: 7px;
  text-align: center;
  line-height: 31px;
  cursor: pointer;
}

.list {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
  margin-top: 36px;
}

.list div {
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
}

.list p {
  font-size: 12px;
  color: #666666;
  text-align: center;
  margin-top: 10px;
}
</style>
