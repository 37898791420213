<template>
  <div class="section">
    <ECharts ref="chart" id='main' :option="option" />
  </div>
</template>

<script>
import * as echarts from 'echarts';
import {
	createComponent
} from 'echarts-for-vue';

export default {
  data() {
    return {
      option: {},
    };
  },
  components: {
    ECharts: createComponent({
    	echarts
    })
  },
  mounted() {
    this.$axios.post('/visitTrend').then(data => {
    	var myChart = echarts.init(document.getElementById('main'));
    	var news = data.newsCount;
    	var seller = data.sellerCount
    	var goods = data.goodsCount.map(function(g, index) {
    		return g + news[index] + seller[index]
    	})
    	myChart.setOption({
    		title: {
    			text: '访问趋势图'
    		},
    		tooltip: {
    			trigger: 'axis'
    		},
    		legend: {
    			data: ['浏览量']
    		},
    		grid: {
    			left: '3%',
    			right: '4%',
    			bottom: '3%',
    			containLabel: true
    		},
    		xAxis: {
    			type: 'category',
    			boundaryGap: false,
    			data: data.time
    		},
    		yAxis: {
    			type: 'value'
    		},
    		series: [{
    			type: 'line',
    			data: goods //goods 产品
    		}]
    	})
    })
  },
};
</script>

<style scoped>
.section {
  height: 400px;
  padding: 35px 20px;
  background-color: #fff;
}
</style>
