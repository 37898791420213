<template>
  <div class="section">
    <div class="tit">概况</div>
    <ul class="list">
      <li>
        <router-link to="/">
          <div>
            <p>商品曝光量</p>
            <span>{{data.exposure}}</span>
          </div>
        </router-link>
      </li>
      <li>
        <router-link to="/product">
          <div>
            <p>在售商品数量</p>
            <span>{{data.goods_count}}</span>
          </div>
        </router-link>
      </li>
      <li>
        <router-link to="/product">
          <div>
            <p>申请中商品数量</p>
            <span>{{data.review_goods}}</span>
          </div>
        </router-link>
      </li>
      <li>
        <router-link to="/product">
          <div>
            <p>未上架商品数量</p>
            <span>{{data.not_sale}}</span>
          </div>
        </router-link>
      </li>
      <li>
        <router-link to="/purchase">
          <div>
            <p>采购信息</p>
            <span>{{data.purchase}}</span>
          </div>
        </router-link>
      </li>
      <li>
        <a :href="$store.state.USER_URL+'/#/caigou_money'">
          <div>
            <p>采购报价</p>
            <span>{{data.quotation}}</span>
          </div>
        </a>
      </li>
      <li>
        <router-link to="/order">
          <div>
            <p>总交易订单数</p>
            <span>{{data.total_order}}</span>
          </div>
        </router-link>
      </li>
      <li>
        <router-link to="/order">
          <div>
            <p>待付款订单</p>
            <span>{{data.no_paid}}</span>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style scoped>
.section {
  padding-left: 21px;
  background-color: #fff;
}

.tit {
  padding-top: 23px;
  font-weight: 700;
}

.list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 63px 45px;
  margin-top: -55px;
}

.list li {
  width: 25%;
  margin-top: 85px;
}

.list li > a {
  width: 100%;
  display: flex;
}

.list li > a::before {
  content: "";
  width: 56px;
  height: 56px;
  flex: none;
  margin-right: 11px;
  background-size: 100% 100%;
}

.list li:nth-of-type(1) > a::before {
  background-image: url(../../assets/img/sum-1.png);
}

.list li:nth-of-type(2) > a::before {
  background-image: url(../../assets/img/sum-2.png);
}

.list li:nth-of-type(3) > a::before {
  background-image: url(../../assets/img/sum-3.png);
}

.list li:nth-of-type(4) > a::before {
  background-image: url(../../assets/img/sum-4.png);
}

.list li:nth-of-type(5) > a::before {
  background-image: url(../../assets/img/sum-5.png);
}

.list li:nth-of-type(6) > a::before {
  background-image: url(../../assets/img/sum-6.png);
}

.list li:nth-of-type(7) > a::before {
  background-image: url(../../assets/img/sum-7.png);
}

.list li:nth-of-type(8) > a::before {
  background-image: url(../../assets/img/sum-8.png);
}

.list li div {
  flex: 1;
  overflow: hidden;
}

.list li p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #666666;
  margin: 6px 0 12px;
}

.list li span {
  font-size: 22px;
}
</style>
