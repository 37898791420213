<template>
  <div class="section">
    <div class="top-left">
      <div class="logo">
       <img v-if="data.seller_logo == null || data.seller_logo == '' || data.seller_logo == undefined " :src="imgSrc" >
       <img v-else :src="$store.state.IMG_URL+'/'+data.seller_logo" />
      </div>
      <div class="top-left-info">
        <div class="title">{{data.company_name}}</div>
        <div class="grade">
          <div class="grade-vip">
            <img :src="data.level_icon">
          </div>
          <div class="grade-text">{{data.level}}</div>
          <div class="grade-time">{{data.age}}年</div>
          <a :href="$store.state.ROOT_URL+'/vip'" target="_blank">会员权益</a>
        </div>
        <div class="auth">
          <div class="item qy" v-if="data.company">企业认证</div>
          <router-link to="/authen" class="item qy-a" v-else>企业认证</router-link>
          <div class="item sm" v-if="data.relname">实名认证</div>
          <router-link to="/authen" class="item sm-a" v-else>实名认证</router-link>
          <div class="item sj" v-if="data.phone">手机认证</div>
          <router-link to="/authen" class="item sj-a" v-else>手机认证</router-link>
          <router-link to="/bindwx" class="item wx" v-if="data.wechat">微信认证</router-link>
          <router-link to="/bindwx" class="item wx-a" v-else>微信认证</router-link>
        </div>
      </div>
    </div>
    <div class="top-right">
      <div class="balance">
        <p>可提现余额：</p>
        <div>
          <div class="balance-num">{{data.balance}}</div>
          <div class="balance-btn">
            <router-link to="/assets">查看明细</router-link>
            <router-link to="/assets">立即提现</router-link>
          </div>
        </div>
      </div>
      <div class="sum">
        <div class="item">累计交易金额：￥{{data.total_amount}}</div>
        <div class="item">交易中：￥{{data.in_trade}}</div>
        <div class="item">累计积分：{{data.credit}}</div>
        <div class="item">现有积分：{{data.integral}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import imgUrl from '../../assets/img/tx.png'
export default {
  props: ['data'],
  data() {
    return {
      imgSrc: "",
    };
  },
};
</script>

<style scoped>
.section {
  display: flex;
  padding: 19px 47px 18px 0;
  background-color: #fff;
}

.top-left {
  display: flex;
  align-items: center;
  padding: 12px 28px 12px 30px;
  border-right: 1px solid #f6f5f7;
  flex: none;
}

.logo {
  width: 135px;
  height: 135px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  max-width: 100%;
  max-height: 100%;
}

.top-left-info {
  margin-left: 22px;
  flex: none;
}

.top-left-info .title {
  height: 46px;
  line-height: 46px;
  font-size: 18px;
}

.grade {
  display: flex;
  font-size: 14px;
  align-items: flex-end;
  margin-top: 8px;
}

.grade a {
  color: #0194fe;
}

.grade-vip {
  margin-right: 9px;
}

.grade-text {
  margin-right: 6px;
}

.grade-time {
  margin-right: ;
}

.grade-time {
  margin-right: 28px;
}

.auth {
  display: flex;
  margin-top: 29px;
}

.auth .item {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-right: 40px;
  color: #000;
}

.auth .item::before {
  content: "";
  width: 25px;
  height: 25px;
  margin-right: 8px;
  background-size: 100% 100%;
}

.auth .qy::before {
  background-image: url(../../assets/img/qy-1.png);
}

.auth .qy-a::before {
  background-image: url(../../assets/img/qy-2.png);
}

.auth .sm::before {
  background-image: url(../../assets/img/sm-1.png);
}

.auth .sm-a::before {
  background-image: url(../../assets/img/sm-2.png);
}

.auth .sj::before {
  background-image: url(../../assets/img/sj-1.png);
}

.auth .sj-a::before {
  background-image: url(../../assets/img/sj-2.png);
}

.auth .wx::before {
  background-image: url(../../assets/img/wx-1.png);
}

.auth .wx-a::before {
  background-image: url(../../assets/img/wx-2.png);
}

.top-right {
  flex: 1;
  padding-left: 34px;
}

.balance {
  margin-top: 12px;
}

.balance p {
  font-size: 14px;
  color: #666666;
}

.balance > div {
  display: flex;
  margin-top: 14px;
}

.balance-num {
  font-size: 30px;
  margin-right: 10px;
}

.balance-num::before {
  content: "￥";
  font-size: 18px;
}

.balance-btn {
  margin-left: auto;
  display: flex;
}

.balance-btn a {
  color: #666ee8;
  font-size: 14px;
  width: 91px;
  height: 32px;
  border: 1px solid #666ee8;
  border-radius: 5px;
  text-align: center;
  line-height: 32px;
  margin-right: 21px;
}

.sum {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  color: #666666;
  margin-top: 19px;
}

.sum .item {
  width: 200px;
  line-height: 26px;
  margin: 2px 20px 0 0;
}
</style>
