<template>
  <div class="home">
    <div class="left">
      <Top :data="topData"/>
      <Sum class="sum" :data="sumData"/>
      <Built class="built" :data="builtDate"/>
      <vip class="vip" />
      <visits class="visits" />
    </div>
    <div class="right">
      <signin :data="signinData" @eventSignIn="handleSignIn" />
      <notice :data="noticeData" />
      <service />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import Top from "@/components/Home/Top.vue";
import Sum from "@/components/Home/Sum.vue";
import Built from "@/components/Home/Built.vue";
import vip from "@/components/Home/Vip.vue";
import visits from "@/components/Home/Visits.vue";

import signin from "@/components/Home/Signin.vue";
import notice from "@/components/Home/Notice.vue";
import service from "@/components/Home/Service.vue";
export default {
  	data() {
  		return {
  			topData: {},
  			sumData: {},
  			signinData: 0,
  			noticeData: {},
  			builtDate:{}
  		}
  	},
  	created() {
  		this.$axios.post('/getSurvey').then(res => {
  			this.topData = {
  				...res.seller,
  				...res.capital,
  				...res.auth
  			}
  			
  			this.sumData = {
  				exposure: res.exposure,
  				goods_count: res.goods_count,
  				review_goods: res.review_goods,
  				not_sale: res.not_sale,
  				purchase: res.purchase,
  				quotation: res.quotation,
  				total_order: res.total_order,
  				no_paid: res.no_paid
  			}
  			this.signinData = res.qiandao
  			this.noticeData = res.sys_msg
  			
  			this.$store.commit('SET_WX_STATE', res.auth.wechat)
  			this.builtDate = {
  				seller_title: res.seller.seller_title,
  				url: res.url,
  			}
  			sessionStorage.setItem('integral',res.capital.integral)
  		})
  		this.$nextTick(()=>{this.toLocal()})
  	},
  	mounted() {
  		let _this=this;
  		_this.$nextTick(function(){
  				window.addEventListener('scroll',_this.handleScroll)
  		})			
  	},
  	methods: {
  		handleSignIn() {
  			this.signinData = 1
  		},
  		toLocal(){
  			//查找存储的锚点id
  			let Id=localStorage.getItem('toId');
  			let toElement=document.getElementById("vip");
  			//锚点存在跳转
  			if(Id){
  				toElement.scrollIntoView()
  			}
  		}
  	},
  	destroyed() {
  		localStorage.setItem('toId','');
  	},
  components: {
    Top,
    Sum,
    Built,
    vip,
    visits,
    signin,
    notice,
    service,
  },
};
</script>
<style>
/* * {
  margin: 0;
  padding: 0;
} */
.home {
  width: 1578px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.home .left {
  width: 1267px;
}
.home .right {
  width: 290px;
}
.home .left .sum,
.built,
.vip,
.visits {
 background-color: #fff;
  margin-top: 20px;
}
</style>
