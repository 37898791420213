<template>
  <div class="section">
    <div class="int">
      <p class="qiandao">每日签到</p>
      <div>
        <router-link to="">领积分</router-link>
        <router-link to="">换广告位</router-link>
      </div>
    </div>
    <div class="btn" v-if="data == 0" @click="handleSign">签到</div>
    <div class="btn already" v-if="data == 1">已签到</div>
  </div>
</template>

<script>
export default {
  methods: {
    handleSign() {
      this.$axios.post("/qiandao").then((data) => {
        if (data.msg == "OK") {
          this.$message.success("签到成功");
          this.$emit("eventSignIn");
        }
      });
    },
  },
  props: ["data"],
};
</script>

<style scoped>
.section {
  background-color: #fff;
  padding: 14px 14px 18px 21px;
  display: flex;
  align-items: center;
}

.section::before {
  content: "";
  width: 33px;
  height: 31px;
  background: url(../../assets/img/sign.png);
  background-size: 100% 100%;
}

.int {
  margin-left: 12px;
}

.int div {
  display: flex;
  margin-top: 9px;
  font-size: 12px;
  margin-left: -10px;
}

.int a {
  color: #666ee8;
  margin-left: 10px;
}

.btn {
  width: 64px;
  height: 30px;
  border-radius: 20px;
  background: #ffb30f;
  color: #fff;
  text-align: center;
  line-height: 30px;
  margin-left: auto;
  font-size: 12px;
  cursor: pointer;
}

.already {
  background: #909399;
}
.qiandao {
  font-size: 14px;
}
</style>
